import {Link} from 'react-router-dom';
import {ReactComponent as FacebookIcon} from "../../icons/ri_facebook-fill.svg";
import {ReactComponent as TwitterIcon} from "../../icons/mdi_twitter.svg";
import {ReactComponent as LinkedInIcon} from "../../icons/ri_linkedin-fill.svg";
import {ReactComponent as TelegramIcon} from "../../icons/telegram-fill.svg";
import './Socials.scss';
import cn from "classnames";

interface SocialItem {
    icon: JSX.Element;
    link: string;
}

const socials: SocialItem[] = [
    {
        icon: <FacebookIcon/>,
        link: 'https://vk.com/radiosvkam'
    },
    {
        icon: <TwitterIcon/>,
        link: 'https://ok.ru/group/52687509192875?st._aid=ExternalGroupWidget_OpenGroup'
    },
    {
        icon: <LinkedInIcon/>,
        link: 'https://wa.me/79247751055'
    },
    {
        icon: <TelegramIcon/>,
        link: 'https://t.me/radiokamchatka'
    }
];
export const Socials = ({className}: { className?: string }) => {
    const socialList = socials.map((item, i) => {
        return (
            <Link key={i} to={item.link} className={'socials-item'}>
                {item.icon}
            </Link>
        )
    })
    return (
        <div className={cn('socials', className)}>{socialList}</div>
    )
}