export const menu = [
    {
        text: 'Главная',
        link: '/'
    },
    {
        text: 'О Радио',
        link: '/team'
    },
    {
        text: 'Программы',
        link: '/programs'
    },
    //    {
    //     text: '',
    //     link: ''
    // },
    // {
    //     text: '',
    //     link: ''
    // },
    // {
    //     text: '',
    //     link: ''
    // },
    {
        text: 'Новости',
        link: '/news'
    },
    {
        text: 'Частоты вещания',
        link: '/waves'
    }
];