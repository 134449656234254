import './News.scss';
import {Breadcrumbs} from "../../components/Breadcrumbs/Breadcrumbs";
import {useEffect, useMemo, useRef, useState} from "react";
import axios from "axios";
import {NewsCard} from "../../components/NewsCard/NewsCard";
import {HorizontalBanner} from "../../components/HorizontalBanner/HorizontalBanner";
import {Modal} from "../../components/layouts/Modal/Modal";
import {Article} from "../../components/Article/Article";
import {useSearchParams} from "react-router-dom";
import {queryNews} from "../../constants/basic";

export const News = () => {

    const [page, setPage] = useState(1);
    const [news, setNews] = useState<any>([]);
    const refForLoadingMoreUsers = useRef(null);
    const [lastNewsId, setLastNewsId] = useState<number | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/news?sort=id%3Adesc&pagination[page]=${page}&pagination[pageSize]=14`)
            .then(({data}) => {
                setLastNewsId(data.data[0].id);
                setNews(data.data)
                setPage(2)
            })
    }, [])

    const showMoreNews = () => {

        axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/news?sort=id%3Adesc&pagination[page]=${page}&pagination[pageSize]=14`)
            .then(({data}) => {
                if (data.data.length) {
                    setNews((news:any) => [...news, ...data.data])
                    setPage(page + 1)
                }
            })
            .catch(err => console.log(err))
    }

    const newsCards = news.map((item: any) => <NewsCard key={item.id} item={item}/>)

    const closeModal = () => {
        searchParams.delete(queryNews);
        setSearchParams(searchParams);
    }

    const newsCardsTemplate = useMemo(() => {
        return newsCards.length ? (
            <>
                {newsCards.slice(0, 4)}

                <HorizontalBanner/>

                {newsCards.slice(4)}
                <div ref={refForLoadingMoreUsers} style={{height: '50px'}}/>
            </>
        ) : (
            <div className={'main-users__no-users-find flex'}>
                <p>Это все новости на данный момент</p>
            </div>
        );
    }, [newsCards])

    useEffect(() => {
        let observer:IntersectionObserver;
        if (refForLoadingMoreUsers.current) {
            observer = new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting) {
                    showMoreNews()
                }
            }, {rootMargin: '200px',})
            observer.observe(refForLoadingMoreUsers.current)
        }

        return () => observer && observer.disconnect();
    }, [refForLoadingMoreUsers.current, newsCards])

    return (
        <div className={'news'}>

            <Modal onClose={closeModal} isOpen={searchParams.has(queryNews)}>
                <Article lastNewsId={lastNewsId}/>
            </Modal>

            <Breadcrumbs currentPageName={'Новости'}/>

            <h1>Все новости</h1>

            <div className="news__grid">

                {newsCardsTemplate}

            </div>

        </div>
    )
}