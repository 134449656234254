import './LastNews.scss';
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {NewsCard} from "../../../components/NewsCard/NewsCard";
import {HorizontalBanner} from "../../../components/HorizontalBanner/HorizontalBanner";
import {Modal} from "../../../components/layouts/Modal/Modal";
import {Article} from "../../../components/Article/Article";
import {queryNews} from "../../../constants/basic";

interface NewsAttributes {
    content: string;
    createdAt: string;
    date: string;
    description: string;
    link: string;
    publishedAt: string;
    title: string;
    updatedAt: string;
}

interface SingleNews {
    attributes: NewsAttributes;
    id: number;
}

export const LastNews = () => {

    const [news, setNews] = useState<SingleNews[] | null>(null);
    const [lastNewsId, setLastNewsId] = useState<number | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/news?sort=id%3Adesc&pagination[pageSize]=6`)
            .then(({data}) => {
                setLastNewsId(data.data[0].id);
                setNews(data.data)
            })
    }, [])

    if (!news) return null;

    const newsCards = news.map((item: any) => <NewsCard key={item.id} item={item}/>)

    const closeModal = () => {
        searchParams.delete(queryNews);
        setSearchParams(searchParams);
    }
    
    return (
        <section className={'last-news'}>

            <Modal onClose={closeModal} isOpen={searchParams.has(queryNews)}>
                <Article lastNewsId={lastNewsId}/>
            </Modal>

            <div className="container">

                <div className="last-news__head">
                    <h2>Новости Камчатки</h2>
                    <Link to={'/news'} className={'button button-light authors-program__head-button'}>
                        Новости Камчатки
                    </Link>
                </div>

                <div className="last-news__grid">

                    {newsCards.slice(0, 4)}
                    

                    <HorizontalBanner/>

                    {newsCards.slice(4)}

                </div>

                <Link to={'/news'} className={'button button-light last-news__button-mobile'}>
                    Все новости
                </Link>

            </div>
        </section>
    )
}
