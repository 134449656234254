import './konkurs.scss';
import {Breadcrumbs} from "../../components/Breadcrumbs/Breadcrumbs";
import Slider from "react-slick";
import {ReactComponent as NavArrowIcon} from "../../icons/arrow-right.svg";
import React, {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import axios from "axios";
import { publicAPI } from '../../constants/basic';

interface Leader {
    attributes: {
        Avatar: {
            data: {
                attributes: {
                    url: string;
                }
            }
        }
        Name: string;
        Specialization: string;
        createdAt: string;
        publishedAt: string;
        updatedAt: string;
    }
    id: number;
}

export const Konkurs = () => {

    const {pathname} = useLocation()
    const sliderRef = useRef<any>(null);
    const [leadersData, setLeadersData] = useState<Leader[]>([]);

    useEffect(() => {
        axios.get(`${publicAPI}/teams/?populate=*`)
            .then(res => setLeadersData(res.data.data))
    }, [])

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"});
    }, [pathname])

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    // const slides = leadersData.map((item) => {
    //     return (
            // <div className={'team__slide'} key={item.id}>
            //     <img
            //         src={`${process.env.REACT_APP_PUBLIC_SV_API}${item.attributes.Avatar.data.attributes.url}`}
            //         alt="Avatar"
            //     />
                {/* <div className="team__slide-inner">
                    <div>{item.attributes.Name}</div>
                    <span>{item.attributes.Specialization}</span>
                </div> */}

    //         </div>
    //     )
    // })

    return (
        <div className={'team'}>

            <Breadcrumbs currentPageName={'ПРАВИЛА УЧАСТИЯ В ИГРАХ / ВИКТОРИНАХ / КОНКУРСАХ'}/>

            {/* <h1>Наша команда</h1> */}

            <div>
                <div className="team__main-text">
                    <div className='panelmenu'>
                        <a className='namemenu'>Правила проведения викторины по истории Камчатского края</a>
                        <ul className='btnDownload'>
                            <a className='file' href='https://inum41.ru/1.pdf'>Скачать</a>
                            {/* <a className='file' href='https://inum41.ru/1.pdf'>Открыть</a> */}
                        </ul>
                    </div>
                    <div className='panelmenu'>
                        <a className='namemenu'>Правила проведения стимулирующего мероприятия (викторины) "НК УЧИТЕЛЬ"</a>
                        <ul className='btnDownload'>
                            <a className='file' href='https://inum41.ru/2.pdf'>Скачать</a>
                            {/* <a className='file' href='https://inum41.ru/1.pdf'>Открыть</a> */}
                        </ul>
                    </div>
                    <div className='panelmenu'>
                        <a className='namemenu'>Правила проведения викторины "Вкусная викторина"                                                    </a>
                        <ul className='btnDownload'>
                            <a className='file' href='https://inum41.ru/3.pdf'>Скачать</a>
                            {/* <a className='file' href='https://inum41.ru/1.pdf'>Открыть</a> */}
                        </ul>
                    </div>
                    <div className='panelmenu'>
                        <a className='namemenu'>Правила проведения стимулирующего мероприятия (викторины) "Ариадна учитель""                                                    </a>
                        <ul className='btnDownload'>
                            <a className='file' href='https://inum41.ru/4.pdf'>Скачать</a>
                            {/* <a className='file' href='https://inum41.ru/1.pdf'>Открыть</a> */}
                        </ul>
                    </div>
                    <div className='panelmenu'>
                        <a className='namemenu'>Правила проведения стимулирующего мероприятия Викторина «СЧАСТЛИВЫЙ БИЛЕТ»"                                                    </a>
                        <ul className='btnDownload'>
                            <a className='file' href='https://inum41.ru/5.pdf'>Скачать</a>
                            {/* <a className='file' href='https://inum41.ru/1.pdf'>Открыть</a> */}
                        </ul>
                    </div>
                    <div className='panelmenu'>
                        <a className='namemenu'>Правила проведения стимулирующего мероприятия Викторина «Счастливый билет театр»"                                                    </a>
                        <ul className='btnDownload'>
                            <a className='file' href='https://inum41.ru/6.pdf'>Скачать</a>
                            {/* <a className='file' href='https://inum41.ru/1.pdf'>Открыть</a> */}
                        </ul>
                    </div>
                    <div className='panelmenu'>
                        <a className='namemenu'>Правила проведения стимулирующего мероприятия Викторина «Пирамида»"                                                    </a>
                        <ul className='btnDownload'>
                            <a className='file' href='https://inum41.ru/7.pdf'>Скачать</a>
                            {/* <a className='file' href='https://inum41.ru/1.pdf'>Открыть</a> */}
                        </ul>
                    </div>
                    <div className='panelmenu'>
                        <a className='namemenu'>Правила проведения стимулирующего мероприятия Викторина «ВКУСНАЯ»"                                                    </a>
                        <ul className='btnDownload'>
                            <a className='file' href='https://inum41.ru/8.pdf'>Скачать</a>
                            {/* <a className='file' href='https://inum41.ru/1.pdf'>Открыть</a> */}
                        </ul>
                    </div>
                    <div className='panelmenu'>
                        <a className='namemenu'>Правила проведения стимулирующего мероприятия Викторина «Раз на Раз»"                                                    </a>
                        <ul className='btnDownload'>
                            <a className='file' href='https://inum41.ru/9.pdf'>Скачать</a>
                            {/* <a className='file' href='https://inum41.ru/1.pdf'>Открыть</a> */}
                        </ul>
                    </div>
                    <div className='panelmenu'>
                        <a className='namemenu'>Правила проведения стимулирующего мероприятия Викторина «Фанты»"                                                    </a>
                        <ul className='btnDownload'>
                            <a className='file' href='https://inum41.ru/10.pdf'>Скачать</a>
                            {/* <a className='file' href='https://inum41.ru/1.pdf'>Открыть</a> */}
                        </ul>
                    </div>
                    <div className='panelmenu'>
                        <a className='namemenu'>Правила проведения стимулирующего мероприятия Викторина «ТЕАТР СЧАСТЛИВЫЙ БИЛЕТ»"                                                    </a>
                        <ul className='btnDownload'>
                            <a className='file' href='https://inum41.ru/11.pdf'>Скачать</a>
                            {/* <a className='file' href='https://inum41.ru/1.pdf'>Открыть</a> */}
                        </ul>
                    </div>
                    <div className='panelmenu'>
                        <a className='namemenu'>Правила проведения стимулирующего мероприятия Викторина «ФАНТЫ НОВАЯ КНИГА»"                                                    </a>
                        <ul className='btnDownload'>
                            <a className='file' href='https://inum41.ru/12.pdf'>Скачать</a>
                            {/* <a className='file' href='https://inum41.ru/1.pdf'>Открыть</a> */}
                        </ul>
                    </div>
                    <div className='panelmenu'>
                        <a className='namemenu'>Правила проведения викторины «Пополняйка»"</a>
                        <ul className='btnDownload'>
                            <a className='file' href='https://inum41.ru/13.pdf'>Скачать</a>
                            {/* <a className='file' href='https://inum41.ru/1.pdf'>Открыть</a> */}
                        </ul>
                    </div>
                    <div>
                        
                    </div>
                </div>

                <div className="team__main-slider">
                    <div className={'team__main-slider-middle-light'}/>

                    {/* <Slider ref={sliderRef} {...settings}>
                        {slides}
                    </Slider> */}
{/* 
                    <div className={'team__main-slider-arrows'}>
                        <div className={'team__main-slider-arrow'} onClick={() => sliderRef.current.slickPrev()}>
                            <NavArrowIcon style={{transform: 'rotate(180deg)'}}/>
                        </div>
                        <div className={'team__main-slider-arrow'} onClick={() => sliderRef.current.slickNext()}>
                            <NavArrowIcon/>
                        </div>
                    </div> */}

                </div>
            </div>

        </div>
    )
}
