import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {RecoilRoot} from "recoil";
import {BrowserRouter} from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <BrowserRouter>
        <RecoilRoot>
            <App/>
        </RecoilRoot>
    </BrowserRouter>
);


serviceWorkerRegistration.register();

reportWebVitals();