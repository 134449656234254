import './Weather.scss';
import {WeatherIn} from "./WeatherIn/WeatherIn";
import {Currency} from "./Currency/Currency";

export const Weather = () => {

    return (
        <section className={'weather-section'}>
            <div className="container">
                <div className="weather-section__inner">
                    <h2>Погода и курс валют</h2>

                    <div className="weather-section__inner-wrap">
                        <img src="/images/weather/weather-back.png" alt="Forest and rocks"/>
                        <WeatherIn/>
                        <Currency/>
                    </div>
                </div>
            </div>
        </section>
    )
}