import './WeatherIn.scss';
import cn from "classnames";
import {useEffect, useState} from "react";
import {ReactComponent as TestIcon} from "../../../../icons/test-Icon.svg";
import axios from "axios";


const days = ['Сегодня', 'Завтра', 'Послезавтра'];

const places = [
    {
        name: 'П-Камчатский',
        json: 'https://weather.kamchatka1.tv/weather/pk',
    },
    // {
    //     name: 'Елизово',
    //     json: 'https://weather.kamchatka1.tv/weather/elz',
    // },
    // {
    //     name: 'Вилючинск',
    //     json: 'https://weather.kamchatka1.tv/weather/vl',
    // },
];

export const WeatherIn = () => {

    const currentPlaceIndex = 0;
    const [weather, setWeather] = useState<any>();
    const [currentDayIndex, setCurrentDayIndex] = useState<number>(0);
    // const [currentPlaceIndex, setCurrentPlaceIndex] = useState(0);

    useEffect(() => {
        axios.get(places[currentPlaceIndex].json)
            .then(({data}) => setWeather(JSON.parse(data).response[currentDayIndex]))
    }, [currentPlaceIndex, currentDayIndex])

    if (!weather) return null;

    const getAverage = (max: number, min: number) => (max + min) / 2;

    const metrics = [
        {
            name: 'Влажность',
            icon: <TestIcon/>,
            result: `${weather.humidity.percent.avg} %`
        },
        {
            name: 'Ветер',
            icon: <TestIcon/>,
            result: `${Math.floor(getAverage(weather.wind.speed.max.m_s, weather.wind.speed.min.m_s))} м/с`
        },
        {
            name: 'Давление',
            icon: <TestIcon/>,
            result: `${Math.floor(getAverage(weather.pressure.mm_hg_atm.max, weather.pressure.mm_hg_atm.min))} мм рт. ст.`
        },
    ];

    const tabs = days.map((day, i) => {
        return (
            <div
                key={day}
                onClick={() => setCurrentDayIndex(i)}
                className={cn('weather-in__nav-tab', {'weather-in__nav-tab_active': currentDayIndex === i})}
            >
                {day}
            </div>
        )
    })

    const metricList = metrics.map((item, i) => {
        return (
            <div
                key={item.name}
                className='weather-in__main-card'
            >
                <div>{item.icon}{item.result}</div>
                {item.name}
            </div>
        )
    })

    return (
        <div className={'weather-in'}>
            <div className="weather-in__head">

                <div className="weather-in__head-title">
                    <h3>П-Камчатский</h3>
                    <span>{weather.description.full}</span>
                    <div>{weather.date.UTC}</div>
                </div>

                <div className="weather-in__head-temp">
                    {`${Math.floor(weather.temperature.air.avg.C)}°C`}
                </div>

                <img src={`/images/weatherIcons/${weather.icon}.svg`} alt="Weather"/>

            </div>

            <div className="weather-in__main">
                {metricList}
            </div>

            <div className="weather-in__nav">

                <div className="weather-in__nav-tabs">
                    {tabs}
                </div>

                <span>{weather.date.UTC}</span>

            </div>
        </div>
    )
}