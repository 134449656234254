import './MainSlider.scss';
import Slider from 'react-slick';
import {ReactComponent as NavArrowIcon} from "../../../icons/slider-arrow.svg";
import {useEffect, useState} from "react";
import axios from "axios";
import { Banner } from '../../../components/layouts/Sidebar/Sidebar';
import { publicAPI } from '../../../constants/basic';

export const MainSlider = () => {

    const [bannersData, setBannersData] = useState<Banner[]>([]);

    const settings = {
        dots: true,
        arrows: true,
        dotsClass: "main-slider__custom-dots",
        infinite: true,
        autoPlay: true,
        autoplaySpeed: 5000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        appendDots: (dots: JSX.Element) => <ul>{dots}</ul>,
        customPaging: (i: number) => (
            <div className="main-slider__custom-dot"/>
        ),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                }
            }
        ]
    };

    useEffect(() => {
        axios.get(`${publicAPI}/main-sliders/?populate=*`)
            .then(res => setBannersData(res.data.data))
    }, [])

    function SampleNextArrow(props: any) {
        const {className, onClick} = props;
        return (
            <div
                className={className}
                onClick={onClick}
            >
                <NavArrowIcon style={{transform: 'rotate(180deg)'}}/>
            </div>
        );
    }

    function SamplePrevArrow(props: any) {
        const {className, onClick} = props;
        return (
            <div
                className={className}
                onClick={onClick}
            >
                <NavArrowIcon/>
            </div>
        );
    }

    const slides = bannersData.map((item) => {
        return (
            <div className={'main-slider__banner-inner_wrap'} key={item.id}>
                <img
                    src={`${process.env.REACT_APP_PUBLIC_SV_API}${item.attributes.Image.data[0].attributes.url}`}
                    className={'bordered'}
                    alt="Banner"
                />
            </div>
        )
    })

    return (
        <section className={'main-slider'}>
            <div className="container">
                <Slider {...settings}>
                    {slides}
                </Slider>
            </div>
        </section>
    )
}
