import './Breadcrumbs.scss';
import {Link} from "react-router-dom";

interface BreadcrumbsProps {
    currentPageName: string
}
export const Breadcrumbs = ({currentPageName}: BreadcrumbsProps) => {
    return (
        <div className={'breadcrumbs'}>
            <Link to={'/'}>Главная / </Link>
            {currentPageName}
        </div>
    )
}