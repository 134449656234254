// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation} from "react-router-dom";
import '@khmyznikov/pwa-install';
import {localStorageTheme} from "./constants/basic";
import {Main} from "./pages/Main/Main";
import {Header} from "./components/layouts/Header/Header";
import {Sidebar} from "./components/layouts/Sidebar/Sidebar";
import {Footer} from "./components/layouts/Footer/Footer";
import {News} from "./pages/News/News";
import {Team} from "./pages/Team/Team";
import {Konkurs} from "./pages/Konkurs/Konkurs";
import {Programs} from "./pages/Programs/Programs";
import {RadioBar} from "./components/layouts/RadioBar/RadioBar";
import {Modal} from "./components/layouts/Modal/Modal";
import {Frequencies} from "./pages/Frequencies/Frequencies";
import {BackgroundLight} from "./components/layouts/BackgroundLight/BackgroundLight";
import './App.scss';

function App() {

    const {pathname} = useLocation()
    const [isDark, setIsDark] = useState(false);
    const [isVideoOpen, setIsVideoOpen] = useState(false);

    useEffect(() => {
        if (localStorage.getItem(localStorageTheme) === 'dark') {
            document.body.classList.add('dark');
            setIsDark(true)
        }
    }, [])

    return (
        <>
            <pwa-install
                install-description="Установить приложение Радио Камчатка?"
                description="Радио Камчатка | 105.5FM"
                name="Радио Камчатка"
            >
            </pwa-install>

            <BackgroundLight top={'-158px'} left={'-291px'} background={'#7214FF'}/>
            <BackgroundLight top={'666px'} left={'-277px'} background={'#32CAFD'}/>
            <BackgroundLight top={'348px'} left={'calc(100% - 217px)'} background={'#32CAFD'}/>
            <BackgroundLight top={'1028px'} left={'calc(100% - 273px)'} background={'#7214FF'}/>

            <Header
                isDark={isDark}
                darkOff={() => setIsDark(false)}
                darkOn={() => setIsDark(true)}
                showVideo={() => setIsVideoOpen(true)}
            />

            <Modal videoModal={true} onClose={() => setIsVideoOpen(false)} isOpen={isVideoOpen}>
                {isVideoOpen && (
                    <iframe
                        src={'https://tv.allkam.ru/kamchatka1/embed.html?autoplay=0'}
                        title="Kamchatka1 tv"
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                )}
            </Modal>


            <main>
                <div className={'container main__container'}>

                    <Routes>
                        <Route path={'/'} element={<Main/>}/>
                        <Route path={'/news'} element={<News/>}/>
                        <Route path={'/team'} element={<Team/>}/>
                        <Route path={'/waves'} element={<Frequencies/>}/>
                        <Route path={'/programs'} element={<Programs/>}/>
                        <Route path={'/konkurs'} element={<Konkurs/>}/>
                    </Routes>

                    {
                        pathname !== '/Konkurs',
                        pathname !== '/team'
                        && pathname !== '/waves'
                        && <Sidebar className={'main__sidebar'}/>
                    }

                </div>
            </main>

            {/* <RadioBar showVideo={() => setIsVideoOpen(true)}/> */}

            <Footer/>
            
            
            

        </>
    );
}

export default App;