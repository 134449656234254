import './Header.scss';
import {menu} from "../../../constants/menu";
import {NavLink, Link} from "react-router-dom";
import {BurgerMenu} from "./BurgerMenu/BurgerMenu";
import {ReactComponent as SunIcon} from "../../../icons/sun.svg";
import React, {useState} from "react";
import cn from "classnames";
import {localStorageTheme} from "../../../constants/basic";
// import RunningLine from "../Header/RuningLine/RunningLine";
import {ReactComponent as PlayIcon} from "../../../../icons/play.svg";
import {Radio} from "../../../components/layouts/RadioBar/Radio/Radio";
import {RadioBar} from '../RadioBar/RadioBar';
import RadioPlayer from './RadioPlayer';
// import {Banner} from './Banner/Banner'

interface HeaderProps {
    isDark: boolean;
    darkOff: () => void;
    darkOn: () => void;
    showVideo: () => void;
}

export const Header = ({isDark, darkOff, darkOn, showVideo}: HeaderProps) => {

    const [isVisibleMenu, setIsVisibleMenu] = useState<boolean>(false);

    const menuList = menu.map((item) => {
        return (
            <li key={item.text} className="header__nav-item">
                <NavLink
                    to={item.link}
                    end
                    className={({isActive}) => (isActive ? 'header__nav-link header__nav-link_active' : 'header__nav-link')}
                >
                    {item.text}
                </NavLink>
            </li>
        )
    })

    const onChangeThemeClick = () => {
        if (localStorage.getItem(localStorageTheme) === 'dark') {
            localStorage.removeItem(localStorageTheme);
            document.body.classList.remove('dark');
            darkOff()
        } else {
            localStorage.setItem(localStorageTheme, 'dark');
            document.body.classList.add('dark');
            darkOn()
        }
    }

    let logoUrl;
    // if (localStorage.getItem(localStorageTheme) === 'dark' || isVisibleMenu) {
    //     logoUrl = '/images/logotypeCV-white.svg';
    // } else {
    //     logoUrl = '/images/logotypeCV.svg';
    // }
    if (localStorage.getItem(localStorageTheme) === 'dark' || isVisibleMenu) {
        logoUrl = '/images/footer-logo.png';
    } else {
        logoUrl = '/images/new-logo-white.png';
    }

    return (
        <header className={'header'}>

            <div className="container">
                <div className="header__inner">

                    <Link to={'/'}>
                        <img src={logoUrl} alt="Radio SV"/>

                    </Link>
                    

                    <nav className={'header__nav'}>
                        <ul className={'header__nav-list'}>
                            {menuList}
                        </ul>
                    </nav>

                    <div className="header__buttons-wrap">


                        <Link to={'/news'} className="button button-light header__button">Новости Камчатки</Link>
                        <a href={'https://wa.me/79247751055'} className="button button-purple header__button">
                            Написать Журналисту
                        </a>

                        <div onClick={onChangeThemeClick} className="header__theme-button">
                            <div
                                className={cn("header__theme-button-inner", {'header__theme-button-inner_white': isDark || isVisibleMenu})}>
                                <SunIcon/>
                            </div>
                        </div>
                    </div>

                    <BurgerMenu
                        showVideo={() => showVideo()}
                        isVisibleMenu={isVisibleMenu}
                        setVisibleMenu={(result: boolean) => setIsVisibleMenu(result)}
                    />

                </div>
            </div>
            <div className='radioshorts'><RadioPlayer src="https://listen.myrh.ru/id057771" /></div>
            {/* <div className='radioshorts'><Radio/></div> */}
            {/* <img src="./radio3.gif" alt="Menu banner" className="menu-banner"/> */}

            {/* <div className='runningline'><RunningLine className={'main-mobile-header__running-line'}/></div> */}
        </header>
    )
}