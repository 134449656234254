import './Team.scss';
import {Breadcrumbs} from "../../components/Breadcrumbs/Breadcrumbs";
// import Slider from "react-slick";
// import {ReactComponent as NavArrowIcon} from "../../icons/arrow-right.svg";
import React, {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import axios from "axios";
import { publicAPI } from '../../constants/basic';

interface Leader {
    attributes: {
        Avatar: {
            data: {
                attributes: {
                    url: string;
                }
            }
        }
        Name: string;
        Specialization: string;
        createdAt: string;
        publishedAt: string;
        updatedAt: string;
    }
    id: number;
}

export const Team = () => {

    const {pathname} = useLocation()
    const sliderRef = useRef<any>(null);
    const [leadersData, setLeadersData] = useState<Leader[]>([]);

    useEffect(() => {
        axios.get(`${publicAPI}/teams/?populate=*`)
            .then(res => setLeadersData(res.data.data))
    }, [])

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"});
    }, [pathname])

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const slides = leadersData.map((item) => {
        return (
            <div className={'team__slide'} key={item.id}>
                <img
                    src={`${process.env.REACT_APP_PUBLIC_SV_API}${item.attributes.Avatar.data.attributes.url}`}
                    alt="Avatar"
                />
                <div className="team__slide-inner">
                    <div>{item.attributes.Name}</div>
                    <span>{item.attributes.Specialization}</span>
                </div>

            </div>
        )
    })

    return (
        <div className={'team'}>

            <Breadcrumbs currentPageName={'О Радио'}/>

            {/* <h1>Наша команда</h1> */}
            <h1>«Радио Камчатка» 105.5 FM — голос Камчатского полуострова!</h1>
            <strong>⭐️25 лет в эфире</strong>

            <div className="team__main">
                <div className="team__main-text">
                    <h4>«Радио Камчатка» — единственная местная FM-радиостанция Камчатского края.
                        Вещает из Петропавловска-Камчатского 24 часа в сутки на частоте 105.5 
                        в FM-диапазоне и онлайн в сети интернет. </h4><br/>
                    <h4>Абсолютная уникальность «Радио Камчатка» — 100% собственное программирование эфира
                         и внеконкурентный охват аудитории полуострова. 
                        Сигнал «Радио Камчатка» принимает практически весь Камчатский край с населением порядка 300 тысяч человек. </h4><br/>
                    <h4>Через сеть ретрансляторов камчатцы слушают нашу радиостанцию в самых отдаленных уголках региона,
                         в том числе в Корякском административном округе за 1000 км от краевого центра. Зона уверенного приема «Радио Камчатка» — крупнейшие населенные пункты:
                         Петропавловск-Камчатский, Елизово, Вилючинск, Усть-Большерецк, Усть-Камчатск, Мильково, Эссо, Оссора и Палана.</h4><br/>
                    <h4>Локальная Камчатская радиостанция на 105,5 FM вышла в эфир в 1999 г. Сегодня команда «Радио Камчатка», 
                        собранная из креативных и талантливых профессионалов, продолжает лучшие традиции местного коммерческого радиовещания, которое в 2000-х гремело на всю страну.
                         В творческих рядах «Радио Камчатка» как опытные «бойцы невидимого фронта», 
                        стоявшие у истоков создания FM-радио в дальневосточном регионе, так и новые громкие имена.</h4><br/>
                    <h4>С 2023 года «Радио Камчатка» входит в состав ведущего 
                        Камчатского мультимедийного медиахолдинга «Масс-Медиа Центр» 
                        с последующей реорганизацией и ребрендингом. </h4><br/>
                    <h4>Кросс-медийные возможности холдинга позволяют информационным и развлекательным проектам «Радио Камчатка» 
                        присутствовать одновременно во всех возможных средах вещания и каналах коммуникаций:
                         от классического радиоэфира и цифрового телевидения до стримов из студии, телеграмм-каналов и соцсетей.</h4><br/>
                    <h4>Второе рождение позволяет говорить о том, что сегодня «Радио Камчатка» остается одновременно самой опытной
                         и в тоже время самой молодой и динамично развивающейся радиостанцией Камчатского полуострова. </h4><br/>
                    <h4>Мы знаем, чем живет Камчатка и что волнует камчатцев. Когда в Москве три часа ночи, в Петропавловске-Камчатском полдень.  </h4><br/>
                    <h4>У Камчатки есть свое радио, которое живет со своими слушателями в одном часовом поясе, одном ритме жизни, на одной волне.</h4><br/>
                    <h3>105,5 FM C такой частотой бьется сердце Камчатки!</h3>
                </div>

                <div className="team__main-slider">
                    <div className={'team__main-slider-middle-light'}/>

                    {/* <Slider ref={sliderRef} {...settings}>
                        {slides}
                    </Slider> */}

                    {/* <div className={'team__main-slider-arrows'}>
                        <div className={'team__main-slider-arrow'} onClick={() => sliderRef.current.slickPrev()}>
                            <NavArrowIcon style={{transform: 'rotate(180deg)'}}/>
                        </div>
                        <div className={'team__main-slider-arrow'} onClick={() => sliderRef.current.slickNext()}>
                            <NavArrowIcon/>
                        </div>
                    </div> */}

                </div>
            </div>

        </div>
    )
}
