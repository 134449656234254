import '../RuningLine/RunningLine.scss';
import {useEffect, useRef, useState} from "react";
import cn from "classnames";
import axios from "axios";
import {ReactComponent as WarningLineIcon} from "../../../../icons/running-line-underline.svg";

export default function RunningLine({className}) {

    const lineRef = useRef(null);
    const wrapWidthRef = useRef(null);
    const [mchsNews, setMchsNews] = useState('');
    const [position, setPosition] = useState(1);
    const wrapWidth = wrapWidthRef.current?.offsetWidth;

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/mchslines`)
            .then(res => setMchsNews(res.data.data[0].attributes))
    }, [])

    useEffect(() => {
        if (wrapWidth) {
            setPosition(wrapWidth);
        }
    }, [wrapWidth])

    function animLine() {
        setPosition((position) => {
            if (position >= -lineRef.current.offsetWidth) {
                return position - 1;
            } else {
                return wrapWidth;
            }
        });
    }

    useEffect(() => {
        if (mchsNews.text?.length > 1) {
            setInterval(animLine, 15)
        }
        return () => clearInterval(animLine)
    }, [mchsNews.text])

    return (
        <div ref={wrapWidthRef} className={cn('running-line', className)}>
            <div className={'running-line__warning-lines'}/>
            <div ref={lineRef} style={{transform: `translate3d(${position}px, 0, 0)`}} className="running-line__inner-wrapper">
                {mchsNews && (
                    <div className={'text-block'}>
                        {mchsNews?.url ? (
                            <a href={mchsNews.url} target={'_blank'} rel={'noreferrer'}>
                                <span className={'text-block__notice'}>Внимание!</span>
                                {mchsNews.text}
                            </a>
                        ) : (
                            <>
                                <span className={'text-block__notice'}>Внимание!</span>
                                {mchsNews.text}
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}