import './HorizontalBanner.scss';
import Slider from "react-slick";
import {useEffect, useState} from "react";
import {Banner} from "../layouts/Sidebar/Sidebar";
import axios from "axios";
import { publicAPI } from '../../constants/basic';

const da = [
    {
        img: '/images/test-slider.png',
        link: '/'
    },
    {
        img: '/images/test-slider.png'
    },
];

export const HorizontalBanner = () => {

    const [bannersData, setBannersData] = useState<Banner[]>([]);

    useEffect(() => {
        axios.get(`${publicAPI}/banner-horizontals/?populate=*`)
            .then(res => setBannersData(res.data.data))
    }, [])

    const settings = {
        dots: false,
        arrows: false,
        dotsClass: "main-slider__custom-dots",
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const slides = bannersData.map((item) => {
        return (
            item.attributes.Link
                ? (
                    <a
                        key={item.id}
                        href={item.attributes.Link}
                        target={'_blank'}
                        rel={'noreferrer'}
                        className={'main-widgets__banner-inner_wrap'}
                    >
                        <img
                            src={`${process.env.REACT_APP_PUBLIC_SV_API}${item.attributes.Image.data[0].attributes.url}`}
                            className={'bordered'}
                            alt="Banner"
                        />
                    </a>
                ) : (
                    <div className={'main-widgets__banner-inner_wrap'} key={item.id}>
                        <img
                            src={`${process.env.REACT_APP_PUBLIC_SV_API}${item.attributes.Image.data[0].attributes.url}`}
                            className={'bordered'}
                            alt="Banner"
                        />
                    </div>
                )
        )
    })

    return (
        <div className={'horizontal-banner'}>
            <Slider {...settings}>
                {slides}
            </Slider>
        </div>
    )
}
