import "./Currency.scss";
import { useEffect, useState } from "react";
import cn from "classnames";
import axios from "axios";
import { regExCurrencies } from "../../../../constants/basic";

const currencies = [
  {
    symbol: "USD",
    endpoint:
      "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd/rub.json",
  },
  {
    symbol: "EURO",
    endpoint:
      "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/eur/rub.json",
  },
  {
    symbol: "CNY",
    endpoint:
      "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/cny/rub.json",
  },
  {
    symbol: "JPY",
    endpoint:
      "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/jpy/rub.json",
  },
  {
    symbol: "BTC",
    endpoint:
      "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/btc/usd.json",
  },
];

export const Currency = () => {
  const [currentCurrencyIndex, setCurrentCurrencyIndex] = useState(0);
  const [currentValue, setCurrentValue] = useState<string | number>("");

  useEffect(() => {
    axios.get(currencies[currentCurrencyIndex].endpoint).then(({ data }) => {
      if (currencies[currentCurrencyIndex].symbol === "BTC") {
        setCurrentValue(`${data.usd.toFixed(0)}$`);
        return;
      }
      setCurrentValue(`${data.rub.toFixed(2)}₽`);
    });
  }, [currentCurrencyIndex]);

  const tabs = currencies.map((item, i) => {
    return (
      <div
        key={item.symbol}
        onClick={() => setCurrentCurrencyIndex(i)}
        className={cn("weather-in__nav-tab", {
          "weather-in__nav-tab_active": currentCurrencyIndex === i,
        })}
      >
        {item.symbol}
      </div>
    );
  });

  return (
    <div className={"currency"}>
      <h4>Курс валют</h4>

      <div className={"currency__number"}>
        <div>{currentValue}</div>
        {currencies[currentCurrencyIndex].symbol}{" "}
        {currentCurrencyIndex === 4 ? "к доллару" : "к Рублю"}
      </div>

      <div className="weather-in__nav-tabs">{tabs}</div>
    </div>
  );
};
