import './Programs.scss';
import {Breadcrumbs} from "../../components/Breadcrumbs/Breadcrumbs";
import {ProgramCard} from "../Main/AuthorsProgram/ProgramCard/ProgramCard";
import {Modal} from "../../components/layouts/Modal/Modal";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {publicAPI} from '../../constants/basic';
import ReactMarkdown from 'react-markdown'

export interface Program {
    attributes: {
        Author: boolean;
        Discription: string;
        Title: string
        Icon: {
            data: {
                attributes: {
                    url: string;
                }
                id: number;
            }
        }
        Image: {
            data: {
                attributes: {
                    url: string;
                }
                id: number;
            }
        }

    }
    id: number;
}

export const Programs = () => {

    const [currentContent, setCurrentContent] = useState<any | null>(null);
    const [programs, setPrograms] = useState<any>([]);

    useEffect(() => {
        axios.get(`${publicAPI}/programs/?populate=*`)
            .then(res => setPrograms(res.data.data))
    }, [])

    const contentWithFullUrlImg = currentContent && currentContent.desc.replaceAll('/uploads/', `${process.env.REACT_APP_PUBLIC_SV_API}/uploads/`);

    const authorProgramCards = programs.filter((x: any) => x.attributes.Author).map((program: any, i: number) => {
        return (
            <ProgramCard
                key={i}
                item={program.attributes}
                openDescription={(content: any) => setCurrentContent(content)}
            />
        )
    })

    const allProgramCards = programs.map((program: any, i: number) => {
        return (
            <ProgramCard
                key={i}
                item={program.attributes}
                openDescription={(content: any) => setCurrentContent(content)}
            />
        )
    })

    // const allProgramCards = allPrograms.map((item) => {
    //     return (
    //         <ProgramCard
    //             key={item.name}
    //             item={item}
    //             openDescription={(content) => setCurrentContent(content)}
    //         />
    //     )
    // })

    return (
        <div className={'programs'}>

            <Modal onClose={() => setCurrentContent(null)} isOpen={Boolean(currentContent)}>
                <div className={'article'}>
                    <div className={'article__inner'}>
                        <div className="article__scroll-wrap program-content">
                            {currentContent && (
                                <>
                                    <h4 className={'program-content__title-wrap'}>
                                        <img
                                            className={'program-content__icon'}
                                            src={currentContent.iconUrl}
                                            alt="Icon"
                                        />
                                        {currentContent.title}
                                    </h4>
                                    <div className={'program-content__content'}>
                                        <ReactMarkdown>{contentWithFullUrlImg}</ReactMarkdown>
                                    </div>
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </Modal>

            <Breadcrumbs currentPageName={'ПРОГРАММЫ'}/>

            <h2>Авторские программы</h2>
            <div className="programs__list">
                {authorProgramCards}
            </div>

            <h2>Все программы</h2>
            <div className="programs__list">
                {allProgramCards}
            </div>

        </div>
    )
}
