import './FooterList.scss';
import {Link} from "react-router-dom";

interface FooterListProps {
    title: string;
    list: {
        link?: string;
        href?: string;
        text: string | JSX.Element;
    }[]
}

export const FooterList = ({title, list}: FooterListProps) => {

    const links = list.map((item,i) => {

        let listItem:JSX.Element;
        if (item.link) {
            listItem = (
                <Link key={i} to={item.link} className={'footer-list__link'}>
                    {item.text}
                </Link>
            )
        } else if (item.href) {
            listItem = (
                <a key={i} href={item.href} className={'footer-list__link'}>
                    {item.text}
                </a>
            )
        } else {
            listItem = (
                <div key={i} className={'footer-list__link'}>
                    {item.text}
                </div>
            )
        }

        return listItem
    })

    return (
        <div className="footer-list">
            <h4>{title}</h4>
            <div className="footer-list__inner-list">
                {links}
            </div>
        </div>
    )
}