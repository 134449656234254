import './ProgramCard.scss';
import {Program} from "../../../Programs/Programs";

interface Programs extends Program {
    item: any
    openDescription: any;
}

export const ProgramCard = ({item, openDescription}: any) => {

    const iconUrl = `${process.env.REACT_APP_PUBLIC_SV_API}${item.Icon.data.attributes.url}`;
    const fullContent: any = {
        iconUrl,
        title: item.Title,
        desc: item.Description
    }
    return (
        <div onClick={() => openDescription(fullContent)} className={'program-card'}>
            <img src={iconUrl} alt="Icon"/>
            <h4>{item.Title}</h4>
        </div>
    )
}
