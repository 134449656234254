import './Frequencies.scss';
import {Breadcrumbs} from "../../components/Breadcrumbs/Breadcrumbs";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";

export const Frequencies = () => {

    const {pathname} = useLocation()

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"});
    }, [pathname])

    return (
        <div className={'frequencies'}>

            <Breadcrumbs currentPageName={'ЧАСТОТЫ ВЕЩАНИЯ'}/>

            <h1>Карта частот вещания</h1>

            <div className="frequencies__map-wrap">
                <iframe
                    src="https://yandex.ru/map-widget/v1/?um=constructor%3A34ed47bfebeb72299e86cb1de330f16e2052e3fad1dc76e8bd337ab4f62fd65e&amp;source=constructor"
                    width="100%"
                    height="576"
                    frameBorder="0"
                />
            </div>

        </div>
    )
}