import './Footer.scss';
// import {ReactComponent as FacebookIcon} from "../../../icons/ri_facebook-fill.svg";
// import {ReactComponent as TwitterIcon} from "../../../icons/mdi_twitter.svg";
// import {ReactComponent as LinkedInIcon} from "../../../icons/ri_linkedin-fill.svg";
// import {ReactComponent as TelegramIcon} from "../../../icons/telegram-fill.svg";
import {Link} from "react-router-dom";
import {FooterList} from "./FooterList/FooterList";
import {menu} from "../../../constants/menu";
import {Socials} from "../../Socials/Socials";
import RunningLine from "../Header/RuningLine/RunningLine";

interface footerInnerList {
    link?: string;
    href?: string;
    text: string | JSX.Element;
}

interface footerList {
    title: string;
    list: footerInnerList[];
}

// const socials: SocialItem[] = [
//     {
//         icon: <FacebookIcon/>,
//         link: 'https://vk.com/radiosvkam'
//     },
//     {
//         icon: <TwitterIcon/>,
//         link: 'https://ok.ru/group/52687509192875?st._aid=ExternalGroupWidget_OpenGroup'
//     },
//     {
//         icon: <LinkedInIcon/>,
//         link: 'https://wa.me/79247751055'
//     },
//     {
//         icon: <TelegramIcon/>,
//         link: 'https://t.me/radiokamchatka'
//     }
// ];



const footerLists: footerList[] = [
    // {
    //     title: 'Меню',
    //     list: menu
    // },
            // {
    //     title: 'Меню',
    //     list: [
    //         {
    //             href: '/',
    //             text: 'Главная'
                
                
    //         },
    //         {
    //             href: '/',
    //             text: 'Наша Команда'
    //         },
    //         {
    //             href: '/',
    //             text: 'Программы'
    //         },
    //         {
    //             href: '/',
    //             text: 'Новости'
    //         },
    //         {
    //             href: '/',
    //             text: 'Частоты '
    //         }
    //     ]

    // },

    {
        title: 'Контакты',
        list: [
            {
                href: '/',
                text: 'Условия использования материалов'
            },
            {
                href: '/',
                text: 'Информация для правообладателей'
            },
            {
                href: 'mailto:reklama@radiokamchatka.com',
                text: 'E-Mail: reklama@radiokamchatka.com'
            },
            {
                href: 'tel:+74152222404',
                text: 'Телефон: 8 (4152) 222-404'
            }
        ]
    },
    {
        title: 'Зона вещания',
        list: [
            {
                // link: '/',
                text: 'Быстринский р-н, с. Эссо  - 103.0 МГц'
            },
            {
                // link: '/',
                text: 'Карагинский р-н, п. Оссора - 102.7 МГц'
            },
            {
                // link: '/',
                text: 'Мильковский р-н, с. Мильково - 103.0 МГц'
            },
            {
                // link: '/',
                text: 'Тигильский р-н, пгт. Палана - 102.0 МГц'
            },
            {
                // link: '/',
                text: 'Усть-Большерецкий р-н, с. Усть-Большерецк - 100.1 МГц'
            },
            {
                // link: '/',
                text: 'Усть-Камчатский р-н, п. Усть-Камчатск - 102.5 МГц'
            }
        ]
    }
];

export const Footer = () => {

    if (window.location.pathname === '/news') return null;

    // const socialList = socials.map((item, i) => {
    //     return (
    //         <Link key={i} to={item.link} className={'footer__social-item'}>
    //             {item.icon}
    //         </Link>
    //     )
    // })

    const lists = footerLists.map((list, i) => {
        return <FooterList key={i} title={list.title} list={list.list}/>
    })

    return (
        <footer className={'footer'}>
            <div className="container">

                <div className="footer__main-wrap footer-main">
                    <img src="/images/footer-logo.png" alt="Radio SV"/>
                    <div className="footer-main__lists">
                        <div className='footer-list__inner-list'>
                            <h4>Меню</h4>
                            <a href='/' className='footer-list__link'>Главная</a>
                            <a href='/team' className='footer-list__link'>Наша команда</a>
                            <a href='/programs' className='footer-list__link'>Программы</a>
                            <a href='/news' className='footer-list__link'>Новости</a>
                            <a href='/waves' className='footer-list__link'>Частоты вещания</a>
                            <a href='/konkurs' className='footer-list__link'>ПРАВИЛА УЧАСТИЯ В ИГРАХ / ВИКТОРИНАХ / КОНКУРСАХ</a>
                        </div>
                        {lists}</div>
                </div>
                <div className='runningline'><RunningLine className={'main-mobile-header__running-line'}/></div>

                <div className="footer__socials-wrap">
                    <div>Радио Камчатка © 2010 \ {new Date().getFullYear()} - Рекламная служба
                        <a href='tel:+74152222404'> 8 (4152) 222-404</a>
                    </div>
                    <Socials className={'footer__socials'}/>
                </div>

            </div>
        </footer>
    )
}