import './Modal.scss';
import {ReactComponent as CrossIcon} from "../../../icons/Cross.svg";
import React, {ReactNode, useEffect} from "react";
import { stopScrollClass } from '../../../constants/basic';
import cn from "classnames";

interface ModalProps {
    onClose: () => void;
    isOpen: boolean;
    children: ReactNode | null;
    videoModal?: boolean;
}

export const Modal = ({onClose, isOpen, children, videoModal}: ModalProps) => {


    useEffect(() => {
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

        if (isOpen) {
            document.body.classList.add(stopScrollClass);
            document.body.style.paddingRight = `${scrollbarWidth}px`;

        } else {
            setTimeout(() => {
                document.body.classList.remove(stopScrollClass);
                document.body.style.paddingRight = '0';

            }, 300)
        }
    }, [isOpen])

    useEffect(() => {
        const onEscape = ({key}: KeyboardEvent) => {
            key === 'Escape' && onClose()
        }

        document.addEventListener('keydown', onEscape);
        return () => document.removeEventListener('keydown', onEscape);
    }, [])

    if (!isOpen) return null;

    const onBackgroundClick = (event: any) => {
        if (event.currentTarget === event.target) {
            onClose()
        }
    }

    return (
        <div onClick={onBackgroundClick} className={'modal'}>
            <div className={cn('modal__inner', {'video-modal': videoModal})}>

                {!videoModal && (
                    <div onClick={() => onClose()} className="modal__closeButton">
                        <div>
                            <CrossIcon/>
                        </div>
                    </div>
                )}

                {children}

            </div>
        </div>
    )
}