import './Sidebar.scss';
import cn from "classnames";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import { publicAPI } from '../../../constants/basic';
import {Socials} from "../../Socials/Socials";

export interface Banner {
    attributes: {
        Image: {
            data: [{
                attributes: {
                    url: string;
                };
                id: number;
            }]
        }
        Link?: string;
        createdAt: string;
        publishedAt: string;
        updatedAt: string;
    };
    id: number;
}

interface SidebarProps {
    className?: string;
}

export const Sidebar = ({className}: SidebarProps) => {

    const {pathname} = useLocation();
    const [marginTop, setMarginTop] = useState(0);
    const [bannersData, setBannersData] = useState<Banner[]>([]);

    useEffect(() => {
        axios.get(`${publicAPI}/banner-verticals/?populate=*`)
            .then(res => setBannersData(res.data.data))
    }, [])

    useEffect(() => {
        if (pathname === '/news' || pathname === '/programs') {
            setMarginTop(126)
        } else {
            setMarginTop(0)
        }
    }, [pathname])

    return (
        <div className={cn('sidebar', className)}>
            <div style={{marginTop: `${marginTop}px`}} className="sidebar__inner">
                <Socials className={'sidebar__socials'}/>
                {bannersData.map((item) => {
                    return (
                        item.attributes.Link
                            ? (
                                <a
                                    key={item.id}
                                    href={item.attributes.Link}
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                    className={'main-widgets__banner-inner_wrap'}
                                >
                                    <img
                                        src={`${process.env.REACT_APP_PUBLIC_SV_API}${item.attributes.Image.data[0].attributes.url}`}
                                        className={'bordered'}
                                        alt="Sidebar banner"
                                    />
                                </a>
                            ) : (
                                <img
                                    key={item.id}
                                    src={`${process.env.REACT_APP_PUBLIC_SV_API}${item.attributes.Image.data[0].attributes.url}`}
                                    className={'bordered'}
                                    alt="Sidebar banner"
                                />
                            )
                    )
                })}
            </div>
        </div>
    )
}
