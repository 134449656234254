import './Main.scss';
import {AuthorsProgram} from "./AuthorsProgram/AuthorsProgram";
import {Weather} from "./Weather/Weather";
import {MainSlider} from "./MainSlider/MainSlider";
import {LastNews} from "./LastNews/LastNews";
import {Sidebar} from "../../components/layouts/Sidebar/Sidebar";

export const Main = () => {
    return (
        <div className={'main'}>
            <MainSlider/>
            <AuthorsProgram/>
            <LastNews/>
            <Weather/>
            <Sidebar className={'main__mobile-sidebar'}/>
            {/* <Weather/> */}
            {/* <LastNews/> */}
            {/* <AuthorsProgram/> */}
        </div>
    )
}