import React, { useState, useEffect, useRef } from 'react';
import './RadioPlayer.css'; // Убедитесь, что стили подключены

interface RadioPlayerProps {
    src: string;
}

const RadioPlayer: React.FC<RadioPlayerProps> = ({ src }) => {
    const audioRef = useRef<HTMLAudioElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(1); // Диапазон от 0 до 1

    const togglePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
                createEmojiRain();  // Вызов функции создания дождя из эмодзи
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newVolume = parseFloat(event.target.value);
        setVolume(newVolume);
        if (audioRef.current) {
            audioRef.current.volume = newVolume;
        }
    };

const createEmojiRain = () => {
    const emojis = ['🎵', '😄', '🌟', '🚀','😻','💃🏻', '🎤','🎶', '💖', '🎉', '🔥']; // Массив возможных эмодзи
    for (let i = 0; i < 120; i++) { // Создание 100 эмодзи
        setTimeout(() => {
            const emoji = document.createElement('div');
            emoji.textContent = emojis[Math.floor(Math.random() * emojis.length)]; // Случайный выбор эмодзи
            emoji.className = 'emoji';
            emoji.style.left = `${Math.random() * 100}vw`; // Распределение по всей ширине экрана
            emoji.style.top = `-${Math.random() * 20 + 20}px`; // Старт над экраном
            document.body.appendChild(emoji);
            setTimeout(() => document.body.removeChild(emoji), 10000); // Удаление эмодзи через 4 секунды
        }, i * 100); // Создание каждого следующего эмодзи с интервалом в 100 мс
    }
};

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume; // Установка начального уровня громкости
        }
    }, []);

    return (
        <div className="radio-player">
            <audio ref={audioRef} src={src} preload="auto" />
            <button className="radio-button" onClick={togglePlayPause}>
                {isPlaying ? (
                    <svg viewBox="0 0 24 24">
                        <path d="M6 6h12v12H6z"></path>
                    </svg>
                ) : (
                    <svg viewBox="0 0 24 24">
                        <path d="M8 5v14l11-7z"></path>
                    </svg>
                )}
            </button>
            <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={handleVolumeChange}
                className="volume-slider"
            />
        </div>
    );
};

export default RadioPlayer;
