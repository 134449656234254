import './NewsCard.scss';
import {useSearchParams} from "react-router-dom";
import {queryNews} from "../../constants/basic";

export const NewsCard = ({item}: any) => {

    const [_, setSearchParams] = useSearchParams();

    return (
        <div className={'news-card'}>

            <div className="news-card__info">
                <h4>{item.attributes.title}</h4>
                <p>{item.attributes.description}</p>
            </div>

            <div className="news-card__link-wrap">
                <div onClick={() => setSearchParams({[queryNews]: item.id})}>Читать полностью</div>
                <span>{item.attributes.date}</span>
            </div>

        </div>
    )
}