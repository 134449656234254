import './BackgroundLight.scss';
import React from "react";

interface BackgroundLightProps {
    top: string;
    left: string;
    background: string;
}

export const BackgroundLight = ({top, left, background}: BackgroundLightProps) => {
    return (
        <div style={{top: top, left: left, background: background}} className={'background-light'}/>
    )
}