import './BurgerMenu.scss';
import {ReactComponent as BurgerLinesIcon} from "../../../../icons/Burger_Icon.svg";
import cn from "classnames";
import {menu} from "../../../../constants/menu";
import {Link, useLocation} from "react-router-dom";
import {useEffect} from "react";
import {Socials} from "../../../Socials/Socials";

interface BackgroundHandler {
    target: EventTarget;
    currentTarget: EventTarget;
}

interface BurgerMenuProps {
    isVisibleMenu: boolean;
    setVisibleMenu: (b: boolean) => void;
    showVideo: () => void;
}

export const BurgerMenu = ({isVisibleMenu, setVisibleMenu, showVideo}: BurgerMenuProps) => {

    const {pathname} = useLocation()

    useEffect(() => {
        setVisibleMenu(false);
    }, [pathname])

    const menuList = menu.map((item) => {
        return (
            <Link to={item.link} key={item.text} className={'burger-menu__item'}>
                {item.text}
            </Link>
        )
    })

    const onBackgroundClick = ({target, currentTarget}: BackgroundHandler) => {
        target === currentTarget && setVisibleMenu(false);
    }

    return (
        <div className={'burger-menu'}>

            <div onClick={() => setVisibleMenu(!isVisibleMenu)} className="burger-menu__button">
                <BurgerLinesIcon/>
            </div>

            <div
                onClick={onBackgroundClick}
                className={cn('burger-menu__mobile-menu', {'burger-menu__mobile-menu_visible': isVisibleMenu})}
            >

                <div className={'burger-menu__inner-wrap'}>

                    <div className="burger-menu__list">

                        {menuList}

                        <div onClick={() => showVideo()} className={'burger-menu__item'}>Смотреть эфир</div>

                    </div>
                    <Socials className={'burger-menu__socials'}/>
                    <a href='tel:+74152222292'
                       className="burger-menu__dropdown-button burger-menu__dropdown-button_call">
                        Звонок в прямой эфир
                    </a>
                    <a href={'https://wa.me/79247751055'} className="burger-menu__dropdown-button">
                        Написать журналисту
                    </a>
                </div>

            </div>

        </div>
        
    )
}