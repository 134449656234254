import './Article.scss';
import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {queryNews} from "../../constants/basic";

interface ResponseAttributes {
    banner: any;
    content: string;
    createdAt: string;
    date: string;
    description: string;
    link: string;
    publishedAt: string;
    title: string;
    updatedAt: string;
}

interface ArticleResponse {
    attributes: ResponseAttributes;
    id: number;
}

interface ArticleProps {
    lastNewsId: number | null;
}

export const Article = ({lastNewsId}: ArticleProps) => {

    const [currentArticle, setCurrentArticle] = useState<ArticleResponse | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/news/${searchParams.get(queryNews)}?populate=*`)
            .then(res => setCurrentArticle(res.data.data))
    }, [])

    if (!currentArticle || !lastNewsId) return null;

    //show banner
    let banner;
    if (currentArticle.attributes.banner.data?.attributes.url) {
        banner = `${process.env.REACT_APP_PUBLIC_API}${currentArticle.attributes.banner.data.attributes.url}`
    }

    //fix content images
    const content = currentArticle.attributes.content.replaceAll('/uploads/', `${process.env.REACT_APP_PUBLIC_API}/uploads/`);

    //find video link
    let rightVideoLink;
    let ruTubeLink;
    if (currentArticle.attributes.link) {

        ruTubeLink = currentArticle.attributes.link.includes('/rutube.ru/');

        if (currentArticle.attributes.link?.includes('watch?v=')) {
            rightVideoLink = currentArticle.attributes.link.split('watch?v=').slice(-1);
        } else if (currentArticle.attributes.link?.includes('/youtu.be/')) {
            rightVideoLink = currentArticle.attributes.link.split('/').slice(-1).join('');
        }
    }

    const onPrevClick = () => {
        let prevNewsIndex: number = currentArticle.id - 1;

        axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/news/${prevNewsIndex}?populate=*`)
            .then(res => {
                if (res.data.data) {
                    setCurrentArticle(res.data.data)
                    setSearchParams({[queryNews]: prevNewsIndex.toString()})
                }
            })
            .catch(err => console.log(err))
    }


    const onNextClick = () => {
        let nextNewsIndex: number = currentArticle.id + 1;

        axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/news/${nextNewsIndex}?populate=*`)
            .then(res => {
                if (res.data.data) {
                    setCurrentArticle(res.data.data)
                    setSearchParams({[queryNews]: nextNewsIndex.toString()})
                }
            })
            .catch(err => {
                if (err.response.status === 404) {
                    if (nextNewsIndex <= lastNewsId) {

                    } else {
                        console.log('its last')
                    }
                }
            })
    }

    return (
        <div className={'article'}>
            <div className={'article__inner'}>

                <span>{currentArticle.attributes.date}</span>

                <div className="article__scroll-wrap">

                    <h3>{currentArticle.attributes.title}</h3>

                    {banner && <img src={banner} alt="Banner"/>}

                    <div className="article__content">
                        <div dangerouslySetInnerHTML={{__html: content}}/>
                    </div>

                    {ruTubeLink ? (
                        <iframe
                            width="720"
                            height="405"
                            src={`https://rutube.ru/play/embed/${currentArticle.attributes.link.split('/').slice(-2, -1)}`}
                            frameBorder="0"
                            allow="clipboard-write; autoplay"
                            allowFullScreen
                        />
                    ) : (
                        <iframe
                            src={`https://www.youtube.com/embed/${rightVideoLink}`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    )}

                </div>

                <div className="article__buttons">
                    <div onClick={onPrevClick} className="button button-light">Предыдущая новость</div>
                    <div onClick={onNextClick} className="button button-purple">Следующая новость</div>
                </div>

            </div>
        </div>
    )
}
