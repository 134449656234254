import './AuthorsProgram.scss';
import {Link} from "react-router-dom";
import {ProgramCard} from "./ProgramCard/ProgramCard";
import React, {useEffect, useState} from "react";
import {Modal} from "../../../components/layouts/Modal/Modal";
import axios from "axios";
import {publicAPI} from "../../../constants/basic";
import ReactMarkdown from "react-markdown";

export const AuthorsProgram = () => {

    const [currentContent, setCurrentContent] = useState<any | null>(null);
    const [programs, setPrograms] = useState<any>([]);

    useEffect(() => {
        axios.get(`${publicAPI}/programs/?populate=*`)
            .then(res => setPrograms(res.data.data))
    }, [])

    const contentWithFullUrlImg = currentContent && currentContent.desc.replaceAll('/uploads/', `${process.env.REACT_APP_PUBLIC_SV_API}/uploads/`);

    const authorProgramCards = programs.filter((x: any) => x.attributes.Author).map((program: any, i: number) => {
        return (
            <ProgramCard
                key={i}
                item={program.attributes}
                openDescription={(content: any) => setCurrentContent(content)}
            />
        )
    })

    return (
        <section className={'authors-program'}>

            <Modal onClose={() => setCurrentContent(null)} isOpen={Boolean(currentContent)}>
                <div className={'article'}>
                    <div className={'article__inner'}>
                        <div className="article__scroll-wrap program-content">
                            {currentContent && (
                                <>
                                    <h4 className={'program-content__title-wrap'}>
                                        <img
                                            className={'program-content__icon'}
                                            src={currentContent.iconUrl}
                                            alt="Icon"
                                        />
                                        {currentContent.title}
                                    </h4>
                                    <div className={'program-content__content'}>
                                        <ReactMarkdown>{contentWithFullUrlImg}</ReactMarkdown>
                                    </div>
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </Modal>

            <div className="container">

                <div className="authors-program__head">
                    <h2>Авторские программы</h2>
                    <Link to={'/programs'} className={'button button-light authors-program__head-button'}>
                        Все программы
                    </Link>
                </div>

                <div className="authors-program__list">
                    {authorProgramCards}
                </div>

                <Link to={'/programs'} className={'button button-light  authors-program__head-button_mobile'}>
                    Все программы
                </Link>

            </div>
        </section>
    )
}
